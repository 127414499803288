import { getString } from '@/content/i18n';
import { isBrowser } from '@/utils/env';
import { QUERY_TYPE } from '@/constants/Alert';
import { runWithAppcues } from '@/analytics/Appcues';
import AppContext from '@/AppContext';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import QueryStore from '@/stores/QueryStore';

import React from 'react';

import type { RouterState, WillRouteToResult } from '@/router/Route';

export default class SearchResultsRoute extends React.Component {
  static getPageTitle({ queryStore }: { queryStore: QueryStore }): string {
    const query = queryStore.getQueryString();
    return query
      ? getString(_ => _.serp.pageTitleWithQuery, query)
      : getString(_ => _.serp.pageTitleWithoutQuery);
  }

  static async willRouteTo(
    appContext: AppContext,
    routerState: RouterState
  ): Promise<WillRouteToResult> {
    const { api, authStore } = appContext;

    const {
      query: { serverSideSearch },
    } = routerState;
    if (!isBrowser() && !serverSideSearch) {
      return QueryStore.setQueryAndLoadingState(appContext, routerState);
    }

    const queryPromise = QueryStore.executeSearchQuery(appContext, routerState);
    const payloads = await Promise.all([queryPromise]);
    const result = payloads[0];
    if (!result.resultData) {
      return result;
    }
    const { matchedEntity } = result.resultData;
    const user = authStore.getUser();
    const shouldFindAlerts = !!user && matchedEntity;
    if (shouldFindAlerts) {
      return [
        ...payloads,
        api.findAlertByQueryTypeAndValue(QUERY_TYPE.ENTITY_PAPER, matchedEntity.id),
      ];
    }
    if (user) {
      runWithAppcues(appcues => appcues.identify(user.hash));
    }
    return payloads;
  }

  render(): React.ReactNode {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-SearchResultsPage" */ './SearchResultsPage'),
          chunkName: 'desktop-SearchResultsPage',
          moduleId: require.resolveWeak('./SearchResultsPage'),
        }}
      />
    );
  }
}
