/* eslint-disable @typescript-eslint/unbound-method */
/* ESLint doesn't like this HOC's usage of bound functions */

import {
  getActiveRoutes,
  getErrorData,
  pageRequiresAuth,
  runActiveWillRouteTos,
  searchStringToObj,
} from './routerUtils';
import {
  handleRedirect,
  maybeGetAuth,
  updateHeadTagContent,
  updatePageMetaDescription,
  updatePageTitle,
} from './clientUtils';

import { ApiErrorRecord } from '@/models/ApiError';
import { checkAndDispatchTriggersFromQueryString } from '@/actions/QueryTriggerActionCreators';
import { flattenDeep } from '@/util';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import { routingForPath } from '@/actions/RouterActionCreators';
import AppContext from '@/AppContext';
import Constants, { ActionValue } from '@/constants';
import Experiment, { getActiveExperimentKeys } from '@/weblab/Experiment';
import logger from '@/logger';
import Pageview from '@/analytics/events/generic/Pageview';
import Redirect from '@/models/redirects/Redirect';
import RouteLoadTimingEvent, {
  RouteLoadTimes,
} from '@/analytics/events/generic/RouteLoadTimingEvent';
import S2History from '@/utils/S2History';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import UnloadEvent from '@/analytics/models/UnloadEvent';

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import type { Location } from 'history';

type TODO__Route = TODO<'Route'>;

type Props = {
  appContext: AppContext;
  history: S2History;
  route: TODO__Route;
  location: Location;
};

type State = {
  apiError: ApiErrorRecord;
};

/**
 * Wraps a top-level component (App/MobileApp) with S2Router. This handles all the client-side
 * routing. React-router decorates what ever components are rendered with renderRoutes with routing
 * state props. These are updated every time we route on the client. So, the way we know to fetch
 * data (and do all our other routing related things) is when the props change via
 * componentWillReceiveProps.
 *
 * @param {React.Element} App - The top-level component to be wrapped with S2Router
 * @returns {React.Element} A wrapped component with S2Router.
 */
export default function withClientRouter(App: React.ComponentType<{ apiError: ApiErrorRecord }>) {
  return class S2Router extends React.Component<Props, State> {
    shouldFetchData: boolean = false;
    _redirectStartTimestamp: Nullable<number> = null;

    static propTypes = {
      appContext: PropTypes.instanceOf(AppContext).isRequired,
      history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
      }),
      route: PropTypes.shape({
        routes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      }).isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
      }),
    };

    static childContextTypes = AppContext.ChildContextTypes;

    getChildContext(): AppContext {
      return this.props.appContext;
    }

    constructor(...args: [any]) {
      super(...args);

      const { apiErrorStore, authStore } = this.props.appContext;

      this.state = { apiError: apiErrorStore.error };

      this.handleApiErrorStoreChange = this.handleApiErrorStoreChange.bind(this);
      this.handleAuthStoreChange = this.handleAuthStoreChange.bind(this);
      this.trackUnload = this.trackUnload.bind(this);

      apiErrorStore.registerComponent(this, this.handleApiErrorStoreChange);
      authStore.registerComponent(this, this.handleAuthStoreChange);
    }

    componentDidMount(): void {
      window.addEventListener('beforeunload', this.trackUnload);
      // `handleRequest` expects `nextProps` to be a separate instance of props for certain equality
      // checks. Given that, and given that objects are mutable, we clone props for this initial
      // mounting.
      this.handleRequest({ ...this.props });
    }

    shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
      const isNewLocation = this.hasLocationChanged(nextProps.location);
      const isNewError = !Immutable.is(this.state.apiError, nextState.apiError);
      const shouldHandleRequest = isNewLocation || this.shouldFetchData;

      if (shouldHandleRequest) {
        this.handleRequest({ isRouting: isNewLocation, ...nextProps });
        this.shouldFetchData = false;
      }
      return shouldHandleRequest || isNewError;
    }

    /* Once we have solved any API errors, attempt to fetch api requests */
    handleApiErrorStoreChange(apiError: ApiErrorRecord, changeAction: ActionValue): void {
      if (changeAction === Constants.actions.CLEAR_ERROR) {
        this.shouldFetchData = true;
      }
      this.setState({ apiError });
    }

    handleAuthStoreChange(): void {
      const {
        history,
        location,
        route,
        appContext: { authStore },
      } = this.props;
      const activeRoutes = getActiveRoutes({
        query: searchStringToObj(location.search),
        pathname: location.pathname,
        routes: [route],
      });

      if (pageRequiresAuth(activeRoutes) && !authStore.hasAuthenticatedUser()) {
        // set timeout to avoid dispatcher conflicts
        setTimeout(() => {
          history.replace('/');
        }, 0);
      }
    }

    trackUnload(): void {
      trackAnalyticsEvent(UnloadEvent.create());
    }

    hasLocationChanged(nextLocation: Location): boolean {
      const { pathname, search } = this.props.location;
      return pathname !== nextLocation.pathname || search !== nextLocation.search;
    }

    trackRouteLoadTiming(routeLoadObj: RouteLoadTimes): void {
      const routeReqStartTimestamp = routeLoadObj.requestTimeWithoutRedirectMs.startTimestamp;
      const routeReqEndTimestamp = routeLoadObj.requestTimeWithoutRedirectMs.endTimestamp;

      // request time including time from redirect prior to request
      routeLoadObj.requestTimeMs = {
        startTimestamp: this._redirectStartTimestamp
          ? this._redirectStartTimestamp
          : routeReqStartTimestamp,
        endTimestamp: routeReqEndTimestamp,
      };

      trackAnalyticsEvent(RouteLoadTimingEvent.create(routeLoadObj));
      this._redirectStartTimestamp = null;
    }

    // To add additional second page load metrics, add a new prop to routeLoadObj below and in RouteLoadTimingEvent.js.
    // Set the startTimestamp and endTimestamp of that new metric in routeLoadObj in the code below.
    // Metrics without an endTimestamp will be set by default to the startTimestamp.
    handleRequest({
      isRouting,
      appContext,
      location,
      history,
      route,
    }: Props & { isRouting?: boolean }): void {
      const routeLoadObj: RouteLoadTimes = {
        authTimeMs: {},
        hasError: {},
        initiatesRedirect: {},
        requestTimeMs: {},
        requestTimeWithoutRedirectMs: {},
        willRouteToTimeMs: {},
      };
      routeLoadObj.requestTimeWithoutRedirectMs.startTimestamp = Date.now();
      const { dispatcher, weblabStore } = appContext;
      const { pathname, search } = location;
      const query = searchStringToObj(search);
      const activeRoutes = getActiveRoutes({
        query,
        pathname,
        routes: [route],
      });
      if (isRouting) {
        dispatcher.dispatch(routingForPath({ query, path: pathname }));
      }

      weblabStore.initializeVariations(getActiveExperimentKeys(Experiment, pathname));

      routeLoadObj.authTimeMs.startTimestamp = Date.now();
      maybeGetAuth({ activeRoutes, appContext })
        .then(() => {
          routeLoadObj.authTimeMs.endTimestamp = Date.now();
          const apiRequests = runActiveWillRouteTos({ activeRoutes, appContext });
          routeLoadObj.willRouteToTimeMs.startTimestamp = Date.now();
          return Promise.all([...apiRequests, checkAndDispatchTriggersFromQueryString(appContext)]);
        })
        .then(data => {
          routeLoadObj.willRouteToTimeMs.endTimestamp = Date.now();
          // Confirm the user has not navigated away while we were loading data.
          if (!this.hasLocationChanged(location)) {
            const redirect = flattenDeep(data).find(obj => obj instanceof Redirect) as
              | Redirect
              | undefined;

            if (redirect) {
              routeLoadObj.requestTimeWithoutRedirectMs.endTimestamp = Date.now();
              routeLoadObj.initiatesRedirect.startTimestamp = true;
              routeLoadObj.hasError.startTimestamp = false;
              this.trackRouteLoadTiming(routeLoadObj);
              this._redirectStartTimestamp = Date.now();
              handleRedirect({ redirect, history });
            } else {
              // After we finish updating metadata, the page is considered ready and we can
              // send metrics.
              updateHeadTagContent({ activeRoutes, appContext, location });
              routeLoadObj.requestTimeWithoutRedirectMs.endTimestamp = Date.now();
              routeLoadObj.initiatesRedirect.startTimestamp = false;
              routeLoadObj.hasError.startTimestamp = false;
              this.trackRouteLoadTiming(routeLoadObj);
              trackAnalyticsEvent(Pageview.create());
            }
          }
        })
        .catch(error => {
          if (!this.hasLocationChanged(location)) {
            this.handleError(error);
          }
          routeLoadObj.requestTimeWithoutRedirectMs.endTimestamp = Date.now();
          routeLoadObj.initiatesRedirect.startTimestamp = false;
          routeLoadObj.hasError.startTimestamp = true;
          this.trackRouteLoadTiming(routeLoadObj);
        });
    }

    handleError(error): void {
      const { title, metaDescription } = getErrorData(error);

      logger.error('Error while routing in the client:', error);

      this.props.appContext.dispatcher.dispatch({
        error,
        actionType: Constants.actions.ROUTING_ERROR,
      });

      updatePageTitle(title);
      updatePageMetaDescription(metaDescription);
    }

    render(): ReactNodeish {
      return <App {...this.props} apiError={this.state.apiError} />;
    }
  };
}
